import '@hotwired/turbo-rails'
import "./controllers"
import "./trix_custom.js"

import "./src/jquery"
window.jQuery = window.$ = require("jquery");

//import { Carousel } from 'bootstrap'
import * as bootstrap from "bootstrap"
window.bootstrap = require("bootstrap")

  // import Carousel from 'bootstrap/js/dist/carousel'


import "./src/custom"
import "./src/uploads"
import "./src/trix-editor-overrides"

import "packery"
var jQBridget = require('jquery-bridget');
var Packery = require('packery');
// make Packery a jQuery plugin
jQBridget( 'packery', Packery, $ );
// now you can use $().packery()

require('jq-numscroll/src/js/core')


console.log("application.js. runs one time only");

document.addEventListener("turbo:load", (event) => {
  console.log("turbo:load. runs every time a page is loaded");

  const hiddenElements = document.querySelectorAll(".hidden");
  hiddenElements.forEach((el) => observer.observe(el));



    const stories = document.querySelector("#StoriesCarousel");
    let itemss = stories.querySelectorAll(".carousel .carousel-item")

    //if (typeof stories == 'undefined') {}

    itemss.forEach((stories) => {
        const minPerSlide = 3
        let next = stories.nextElementSibling
        for (var i=1; i<minPerSlide; i++) {
            if (!next) {
                // wrap carousel by using first child
              next = itemss[0]
            }
            let cloneChild = next.cloneNode(true)
            stories.appendChild(cloneChild.children[0])
            next = next.nextElementSibling
        }
    })







});


// ----------DATATABLES ------- //
//ES6 syntax
//import 'datatables.net-bs5'

//Es3 syntax
//var $ = require( 'jquery' );
require( 'datatables.net-bs5' )( window, $ );
//import DataTable from "datatables.net-bs5"
//import buttons from "datatables.net-buttons-bs5"
//window.DataTable = DataTable();
//buttons();

//import "datatables.net-bs5";
//import "datatables.net-buttons-bs5";
// import "datatables.net-buttons/js/buttons.html5.min.js";
// import "datatables.net-buttons/js/buttons.colvis.min.js";

// ----------DATATABLES ------- //

import autosize from "autosize";
//global.autosize = autosize;

//import 'bs5-lightbox';
import Lightbox from 'bs5-lightbox';

import "chartkick"
import "chart.js"

window.Chartkick = Chartkick
Chartkick.addAdapter(Chart)
Chartkick.options = {
  library: {animation: {easing: 'easeOutQuart'}},
}




//import "@fortawesome/fontawesome-free/js/all";

import "waypoints/lib/jquery.waypoints.js";


import "./animations.js"

import "./lightbox.js"




// import { Application } from "stimulus"
// import Lightbox from "stimulus-lightbox"

// const application = Application.start()
// application.register("lightbox", Lightbox)

// import "lightgallery.js/dist/css/lightgallery.min.css"




import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()



//import { DirectUpload } from "@rails/activestorage"

const input = document.querySelector('input[type=file]')

// Bind to file drop - use the ondrop on a parent element or use a
//  library like Dropzone
const onDrop = (event) => {
  event.preventDefault()
  const files = event.dataTransfer.files;
  Array.from(files).forEach(file => uploadFile(file))
}

// Bind to normal file selection
input.addEventListener('change', (event) => {
  Array.from(input.files).forEach(file => uploadFile(file))
  // you might clear the selected files from the input
  input.value = null
})

const uploadFile = (file) => {
  // your form needs the file_field direct_upload: true, which
  //  provides data-direct-upload-url
  const url = input.dataset.directUploadUrl
  const upload = new DirectUpload(file, url)

  upload.create((error, blob) => {
    if (error) {
      // Handle the error
    } else {
      // Add an appropriately-named hidden input to the form with a
      //  value of blob.signed_id so that the blob ids will be
      //  transmitted in the normal upload flow
      const hiddenField = document.createElement('input')
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("value", blob.signed_id);
      hiddenField.name = input.name
      document.querySelector('form').appendChild(hiddenField)
    }
  })
}

class Uploader {
  constructor(file, url) {
    this.upload = new DirectUpload(this.file, this.url, this)
  }

  upload(file) {
    this.upload.create((error, blob) => {
      if (error) {
        // Handle the error
      } else {
        // Add an appropriately-named hidden input to the form
        // with a value of blob.signed_id
      }
    })
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress",
      event => this.directUploadDidProgress(event))
  }

  directUploadDidProgress(event) {
    // Use event.loaded and event.total to update the progress bar
  }
}
import "trix"
import "@rails/actiontext"


document.addEventListener('trix-before-paste', function (e) {
  if (e.paste.hasOwnProperty('html')){
    let div = document.createElement("div");
    div.innerHTML = e.paste.html;
    e.paste.html = div.textContent;
  }
});

