document.addEventListener("turbo:load", function(event){



})


document.addEventListener("turbo:load", function () {


    //$('body').css('display', 'none');
    //$('body').fadeIn(1000);



    $('.masonry').packery({
      // options
      itemSelector: '.item',
      gutter: 15
    });


    $("#department").change(function() {
        console.log('canvia?')
        var form_url = $(this).find(':selected').data('url');
        $('#department-form').prop('action', form_url).trigger('submit');

    });

    
    
    $(".blockorder").change(function() {
        
        var modelForm = document.querySelector("#block_" + $(this).data('id') + "_form");
        
        console.log("#block_" + $(this).data('id') + "_form");
    
        modelForm.requestSubmit();
    
    });




    $(".workshoporder").change(function() {
        
        var modelForm = document.querySelector("#workshop_" + $(this).data('id') + "_form");
        
        console.log('cambia');
        console.log("#workshop_" + $(this).data('id') + "_form");
    
        modelForm.requestSubmit();
    
    });




    $(function(){
        console.log("jquery està en marxa pero no canvia")
    })

    $('body').scrollspy({ target: '#scrollnav' })


 })
