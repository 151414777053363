// app/javascript/trix-editor-overrides.js
window.addEventListener("trix-file-accept", function(event) {
  console.log('trix-editor-overrides')
  const maxFileSize = 1024 * 1024 * 20 // 20MB 
  if (event.file.size > maxFileSize) {
    event.preventDefault()
    alert("No accepta arxius de més de 20 mb")
  }
})


// document.addEventListener('turbo:before-fetch-response', (event) => {
//   if(event.detail.fetchResponse.status === 413) {
//     console.log('Payload Too Large')
//   }
// });