var Trix = require("trix")
console.log("Config", Trix.config);

/* what the newly created button does */


Trix.config.blockAttributes.heading2 = {
  tagName: 'h2'
}
Trix.config.blockAttributes.heading3 = {
  tagName: 'h3'
}
Trix.config.textAttributes.underline = {
  tagName: 'u'
}


// Trix.config.textAttributes.red = {
//     style: { color: "red" },
//     parser: function(element) {
//         return element.style.color === "red"
//     },
//     inheritable: true
// }

/* insert the button visual in the default toolbar */
addEventListener("trix-initialize", function(event) {
    //var buttonHTML = '<button type="button" data-trix-attribute="red">RED</button>'


    var buttonH2 = '<button type="button" class="trix-button" data-trix-attribute="heading2" title="h2" tabindex="-1">h2</button>'
    var buttonH3 = '<button type="button" class="trix-button" data-trix-attribute="title-delta" title="h3" tabindex="-1">h3</button>'
   

    event.target.toolbarElement.
    querySelector(".trix-button-group").insertAdjacentHTML("beforeend", buttonH2 + buttonH3)
})