
document.addEventListener("turbo:load", function () {





    //set a waypoint for all the page parts on the page
    var tunachange = $('.tuna-change').waypoint(function(direction) {
        //check the direction
        if(direction == 'down') {
            //add the class to start the animation
            $(this.element).addClass('fadeblue');
            //then destroy this waypoint, we don't need it anymore
            this.destroy();
        }
    }, {
        //Set the offset
        offset: '80%'
    });




    //set a waypoint for all the page parts on the page
    var ppWaypoint = $('.pp').waypoint(function(direction) {
        //check the direction
        if(direction == 'down') {
            //add the class to start the animation
            $(this.element).addClass('show');
            //then destroy this waypoint, we don't need it anymore
            this.destroy();
        }
    }, {
        //Set the offset
        offset: '80%'
    });



    //set a waypoint for all the page parts on the page
    var startnum = $('.startnum').waypoint(function(direction) {
        //check the direction
        if(direction == 'down') {
        	$(this.element).numScroll();
            this.destroy();
        }
    }, {
        //Set the offset
        offset: '80%'
    });






   







    /* PROGRESS BARS*/


    $('#sg1').waypoint(function(direction) {
	    if(direction == 'down') {
	        $(this.element).addClass('show');
	        $('#sg1a').animate({ width: "30%" }, 2500);
	        this.destroy();
	    }}, { offset: '80%'});


    $('#sg2').waypoint(function(direction) {
	    if(direction == 'down') {
	        $(this.element).addClass('show');
	        $('#sg2a').animate({ width: "90%" }, 2500);
	        this.destroy();
	    }}, { offset: '80%'});


    $('#sg3').waypoint(function(direction) {
	    if(direction == 'down') {
	        $(this.element).addClass('show');
	        $('#sg3a').animate({ width: "90%" }, 2500);
	        this.destroy();
	    }}, { offset: '80%'});


    $('#sg4').waypoint(function(direction) {
	    if(direction == 'down') {
	        $(this.element).addClass('show');
	        $('#sg4a').animate({ width: "90%" }, 2500);
	        this.destroy();
	    }}, { offset: '80%'});


    $('#sg5').waypoint(function(direction) {
	    if(direction == 'down') {
	        $(this.element).addClass('show');
	        $('#sg5a').animate({ width: "90%" }, 2500);
	        this.destroy();
	    }}, { offset: '80%'});


    $('#sg6').waypoint(function(direction) {
	    if(direction == 'down') {
	        $(this.element).addClass('show');
	        $('#sg6a').animate({ width: "90%" }, 2500);
	        this.destroy();
	    }}, { offset: '80%'});





$(function() {
  var itemQueue = []
  var delay = 60
  var queueTimer

  function processItemQueue () {
    if (queueTimer) return // We're already processing the queue
    queueTimer = window.setInterval(function () {
      if (itemQueue.length) {
        $(itemQueue.shift()).addClass('show');
        processItemQueue()
      } else {
        window.clearInterval(queueTimer)
        queueTimer = null
      }
    }, delay)
  }

  var waypoints = $(".fadeinblock").waypoint(function () {
    itemQueue.push(this.element)
    processItemQueue()
  }, {
    offset: '90%'
  })
})











})